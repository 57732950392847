<template>
  <div class="search-container">
    <div class="title">搜索</div>
    <div class="input_box">
      <el-input v-model="searchInput" @input="getSearchSuggest" @keydown.enter.native="getSearchSuggest"></el-input>
      <span style="position: absolute;left: 235px;top: 30px"><i class="el-icon-search"></i></span>
    </div>
    <ul class="tabs">
      <li :class="{'active': activeTag == 0}" @click="switchType(0)">全部</li>
      <li :class="{'active': activeTag == 1}" @click="switchType(1)">文章{{ articleLength }}</li>
      <li :class="{'active': activeTag == 2}" @click="switchType(2)">快讯{{ fastLength }}</li>
    </ul>
    <ul class="result_list">
      <li class="list_item"
          v-for="(item,index) in tempShowList.slice((pageInfo.pageIndex - 1) * 10,pageInfo.pageIndex * 10)"
          :key="index">
        <template v-if="item.category == 1">
          <div class="cover">
            <img :src="item.imageUrl">
          </div>
          <div class="content_container">
            <div class="title" @click="skipDetails(item)" v-html="item.title"></div>
            <div class="content">{{ item.introduction }}</div>
            <div class="tools">
              <div class="author">
                <img v-if="item.iconUrl" :src="item.iconUrl"/>
                <img v-else src="../../assets/image/authorImg.png">
                <span class="nick_name">{{ item.authorName }}</span>
              </div>
              <ul class="tags" v-if="item.labelList">
                <li v-for="(lable, index2) in item.labelList.slice(0,4)" :key="index2"
                    @click="shipTagList(lable.ltName)">
                  {{ lable.ltName }}
                </li>
              </ul>
              <span>{{ item.showTime }}</span>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="content_container">
            <div class="title" @click="skipDetails(item)" v-html="item.title"></div>
            <div class="content" v-html="item.introduction" ></div>
            <div class="tools_fast">
              <span>{{ item.showTime }}</span>
              <span v-if="item.source != null && item.source != '' ">来源: {{ item.source }}</span>
            </div>
          </div>
        </template>

      </li>
    </ul>

    <!-- 分页 -->
    <div v-show="tempShowList.length > 0">
      <el-pagination
          class="pagination"
          background
          @current-change="handleCurrentChange"
          :current-page="pageInfo.pageIndex"
          :page-sizes="[5, 10, 20, 50]"
          :page-size="pageInfo.pageSize"
          layout="prev, pager, next, jumper"
          :total="pageInfo.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
let timer;

export default {
  name: "index",
  data: () => ({
    searchInput: '',
    article: [],
    articleLength: 0,
    fast: [],
    fastLength: 0,
    activeTag: 0,
    allSearchResult: [],
    tempShowList: [],
    // 分页对象
    pageInfo: {
      pageIndex: 1,
      pageSize: 5,
      total: 10
    }
  }),
  created() {
    this.searchInput = this.$route.params.searchVal
    this.getSearchSuggest()
  },
  beforeRouteUpdate(to, from, next) {
    this.searchInput = to.params.searchVal
    next()
  },
  methods: {
    skipDetails(item) {
      let atUuid = item.atUuid ? item.atUuid : item.id;
      // this.$router.push('/article-detail/' + atUuid)
      let routeUrl;
      if (this.activeTag == 1 || this.activeTag == 0) {
        if(item.category && item.category == 0){
          routeUrl = this.$router.resolve({path: '/fast-detail/' + atUuid});
        }else{
          routeUrl = this.$router.resolve({path: '/article-detail/' + atUuid});
        }
      } else {
        routeUrl = this.$router.resolve({path: '/fast-detail/' + atUuid});
      }
      window.open(routeUrl.href, '_blank');
    },
    switchType(val) {
      if (val == this.activeTag) {
        return
      }
      this.activeTag = val
      this.pageInfo.pageIndex = 1
      if (val == 0) {
        this.tempShowList = this.allSearchResult

        this.pageInfo.total = this.tempShowList.length
        return
      }
      if (val == 1) {
        this.tempShowList = this.article

        this.pageInfo.total = this.article.length
      }
      if (val == 2) {
        this.tempShowList = this.fast

        this.pageInfo.total = this.fast.length
      }
    },
    getSearchSuggest() {
      // 防抖
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        // 处理事件
        this.axios.get('/article/search', {params: {text: this.searchInput}}).then(res => {
          this.article = res.article
          this.articleLength = res.article.length
          this.fast = res.fast
          this.fastLength = res.fast.length
          this.allSearchResult = res.article.concat(res.fast)
          this.tempShowList = [...this.allSearchResult]
          this.pageInfo.pageIndex = 1
          this.pageInfo.pageSize = 10
          this.pageInfo.total = this.tempShowList.length
        })
        timer = undefined;
      }, 400);

    },
    //  pageIndex 改变
    handleCurrentChange(val) {
      this.pageInfo.pageIndex = val
    },
    shipTagList(name) {
      this.$router.push('/tag-list/' + name)

    }
  }
}
</script>

<style lang="scss" scoped>
.search-container {
  width: 100%;
  background: #FFFFFF;
  font-family: Microsoft YaHei;
  padding: 30px 30px 50px;
  min-height: 60vh;
  margin-bottom: 40px;

  .title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
  }

  .input_box {
    position: relative;

    /deep/ .el-input__inner {
      width: 620px;
      background: #FFFFFF;
      border-radius: 6px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 20px;
      margin-bottom: 50px;
      box-shadow: none;
      border: 2px solid #333333;
      padding-left: 30px;

      :focus {
        border-color: #333333;
        border-width: 2px;
        outline: 0;
      }
    }
  }


  .tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 400;
    color: #999999;
    margin-bottom: 8px;
    cursor: pointer;
    position: relative;

    &:before {
      content: '';
      width: calc(100% + 60px);
      height: 1px;
      background: #EFEFEF;
      position: absolute;
      left: -30px;
      bottom: -8px;
    }

    .active {
      color: #333333;
      font-weight: bold;
      position: relative;

      &:before {
        content: '';
        width: 100%;
        height: 4px;
        background: #3E35E7;
        position: absolute;
        left: 0;
        bottom: -8px;
        border-radius: 4px;
      }
    }

    :not(:last-child) {
      margin-right: 30px;
    }
  }

  .result_list {

    .list_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;
      position: relative;

      &:not(:last-child):before {
        content: '';
        width: 100%;
        height: 1px;
        background: #EFEFEF;
        position: absolute;
        bottom: 0px;
      }

      .cover {
        width: 140px;
        height: 100px;
        margin-right: 20px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 4px;
        }
      }

      .content_container {
        flex: 1;
        width: 0;
        position: relative;
        top: -5px;

        .title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          line-height: 41px;
          color: #000000;
          text-align: left;
          cursor: pointer;
        }

        .content {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          font-size: 14px;
          line-height: 30px;
          color: #000000;
          //min-height: 80px;
        }

        .tools {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;

          .author {
            display: flex;
            align-items: center;

            img {
              height: 30px;
              width: 30px;
              object-fit: cover;
              border-radius: 50%;
              margin-right: 5px;
            }

            .nick_name {
              width: 70px;
              font-size: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .tags {
            flex: 1;
            display: flex;
            cursor: pointer;

            li {
              height: 24px;
              line-height: 24px;
              box-sizing: border-box;
              text-align: center;
              background: #ECECEC;
              font-size: 12px;
              color: #3E35E7;
              border-radius: 12px;
              padding: 0 8px;

              &:not(:last-child) {
                margin-right: 10px;
              }
            }
          }


          .show-time {
            color: #999999;
          }
        }

        .tools_fast {
          font-size: 14px;
          color: #999999;

          :first-child {
            margin-right: 50px;
          }
        }
      }
    }

  }

  /deep/ .el-pagination.is-background .btn-next, /deep/ .el-pagination.is-background .btn-prev, /deep/ .el-pagination.is-background .el-pager li {
    min-width: 36px;
    height: 36px;
    line-height: 36px;
    border: 1px solid #ECECEC;
    border-radius: 6px;
  }

  /deep/ .el-pagination__jump {
    line-height: 36px;
    height: 36px;

    input:focus {
      border-color: #3E35E7;
    }
  }

  /deep/ .pagination {
    margin: 30px 0;
    text-align: center;

    .el-pager li:not(.disabled).active {
      background: #3E35E7;
    }

    .el-pager li:not(.disabled):not(.active) {
      background: #FFFFFF;
    }

    .el-pager li:not(.disabled):not(.active):hover {
      color: #3E35E7;
    }
  }
}


</style>
